import { atom, selector } from 'recoil';
import { round } from 'src/utils/number';
import { Storage } from 'src/utils/storage';

const INKLVAT_KEY = 'inkl_vat';

export type VAT = {
  inclVAT: boolean;
  value: number;
};

export const vatState = atom({
  key: 'vatState',
  default: {
    inclVAT: Storage.getItem(INKLVAT_KEY) ?? true,
    value: 0.12
  } as VAT
});

export const inclVATState = selector({
  key: 'exclVATState',
  get: ({ get }) => {
    const vat = get(vatState);
    return vat.inclVAT;
  },
  set: ({ set, get }, newValue) => {
    const vat = get(vatState);
    set(vatState, {
      ...vat,
      inclVAT: newValue as boolean
    });

    Storage.setItem(INKLVAT_KEY, newValue);
  }
});

export const valueVATState = selector({
  key: 'valueVATState',
  get: ({ get }) => {
    const vat = get(vatState);
    return vat.value;
  }
});

export const addVAT = (value: number | null | undefined, vat: VAT) => {
  if (!value) return value;

  if (vat.inclVAT) return round(value * (1 + vat.value), 2);

  return value;
};

export const removeVAT = (value: number | null | undefined, vat: VAT) => {
  if (!value) return value;

  if (vat.inclVAT) return round(value / (1 + vat.value), 2);

  return value;
};
