import { PropsWithChildren, ReactNode } from "react";
import { AuthorizationError } from "src/components/pages/Error/AuthorizationError";
import { UserRole } from "src/data/models/user";
import { useAuth } from "src/state/contexts/AuthContext";

export type RequireRoleProps = {
  role?: UserRole | UserRole[];
  fallback?: ReactNode;
};

export function RequireRole(props: PropsWithChildren<RequireRoleProps>) {
  const auth = useAuth();

  const requiredRoles = Array.isArray(props.role) ? props.role : (props.role ? [ props.role ] : []);
  const isAuthenticated = requiredRoles.length == 0 || auth.userRoles.some(role => role == 'superadmin' || requiredRoles.some(r => r == role));

  return <>{isAuthenticated ? props.children! : (props.fallback ?? <AuthorizationError />)}</>;
}