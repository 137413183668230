import { useMediaQuery } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import fourcLogo from 'src/assets/images/logos/fourc_logo.png';
import { breakpoints } from 'src/assets/_responsive-mixin';
import { Icon } from 'src/components/shared/Icon/Icon';
import { useAuth } from 'src/state/contexts/AuthContext';
import { getNavItems, isNavItem, NavItem, NavItemChild } from './nav-items';
import {
  Logo,
  SidebarContainer,
  SidebarItem,
  SidebarItemChild,
  SidebarItemChildList,
  SidebarItemList,
  SidebarNav
} from './Sidebar.styles';

type SidebarProps = {
  isSidebarOpen: boolean;
  onToggleSidebar: () => void;
};

export const Sidebar = ({ onToggleSidebar, isSidebarOpen }: SidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const navItems = getNavItems();
  const { userRoles } = useAuth();

  const [activeItem, setActiveItem] = useState<NavItem | null>(null);
  const [activeChild, setActiveChild] = useState<NavItemChild | null>(null);

  const smallScreen = useMediaQuery(`(max-width: ${breakpoints.small})`);

  const isNavItemActive = (navItem: NavItem, path: string) =>
    (navItem.route && path?.includes(navItem.route)) ||
    (navItem.children && navItem.children.some(child => path.includes(child.route)));

  const initializeSidebar = useCallback(
    (path: string) => {
      navItems.forEach(item => {
        if (isNavItemActive(item, path)) {
          setActiveItem(item);
          setActiveChild(item.children?.find(c => path.includes(c.route)) ?? null);
        }
      });
    },
    [navItems]
  );

  useEffect(() => {
    initializeSidebar(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavItemClick = (item: NavItem | NavItemChild) => {
    if (activeItem?.name === item.name) {
      setActiveItem(null);
    } else {
      if (isNavItem(item)) {
        setActiveItem(item);
      } else {
        setActiveChild(item);
      }
    }

    if (item.route) {
      navigate(item.route);

      if (smallScreen) {
        onToggleSidebar();
      }
    }
  };

  const isNavItemVisible = (item: NavItem | NavItemChild) => {
    if (item.roles) {
      return userRoles.some(role => item.roles?.includes(role));
    }
    return true;
  };

  return (
    <SidebarContainer className={isSidebarOpen ? 'open' : ''}>
      <SidebarNav>
        <SidebarItemList>
          {navItems.filter(isNavItemVisible).map(item => {
            const visibleChildItems = item.children?.filter(isNavItemVisible) ?? [];
            return (
              <Fragment key={item.name}>
                <SidebarItem
                  active={activeItem?.name === item.name && !!activeItem.route}
                  disabled={item.disabled}
                  onClick={() => !item.disabled && onNavItemClick(item)}
                >
                  <Icon className="mr-8" type={item.icon} />
                  {t(`${item.name}`)}
                  {!!item.children && <Icon size={12} type="la-angle-down" className="ml-auto" />}
                </SidebarItem>

                {!!item.children && (
                  <SidebarItemChildList style={{maxHeight: activeItem?.name === item.name ? "100%" : "0"}}>
                    {visibleChildItems.map(child => (
                      <SidebarItemChild
                        key={child.name}
                        active={activeChild?.name === child.name}
                        disabled={activeChild?.disabled}
                        onClick={() => onNavItemClick(child)}
                      >
                        {t(`${child.name}`)}
                      </SidebarItemChild>
                    ))}
                  </SidebarItemChildList>
                )}
              </Fragment>
            );
          })}
        </SidebarItemList>
      </SidebarNav>

      <Logo src={fourcLogo} alt="FourC logo" />
    </SidebarContainer>
  );
};
