import toast from "react-hot-toast";
import { getRequest } from "src/data/api-handler";
import { SettingsDto } from "src/data/models/v2/settings/settingsDto";

export interface Smartbuss2Config {
    REACT_APP_APP: string;

    REACT_APP_API: string;

    REACT_APP_KEYCLOACK_URL: string;
    REACT_APP_KEYCLOACK_CLIENT_ID: string;
    REACT_APP_KEYCLOACK_REALM: string;

    REACT_APP_TICKET_URL: string;

    REACT_APP_IS_TEST: boolean | null;

    REACT_APP_IANA_TIMEZONE: string | null;
}

export const loadSmartbuss2Config = (onLoadedCallback: () => void) => {
    fetch("/config.json")
        .then(res => res.json())
        .then(config => window.smartbuss2 = config)
        .then(() => onLoadedCallback())
        .catch(() => {
            onLoadedCallback();
        });
};

export const loadCurrentBVTSettings = () => {
  getRequest<SettingsDto>('/api/v2/Settings/GetCurrent')
    .then(res => {
      if (res.ok) {
        window.bvtSettings = res.data;
      } else {
        toast.error('Could not get BVT settings.')
      }
    })
}

export const getBVTSettings = () => {
  return window.bvtSettings;
}

export const getAppBaseUrl = (): string => {
    return window.smartbuss2?.REACT_APP_APP ?? process.env.REACT_APP_APP;
}

export const getApiBaseUrl = (): string => {
    return window.smartbuss2?.REACT_APP_API ?? process.env.REACT_APP_API;
}

export const getTicketBaseUrl = (): string => {
    return window.smartbuss2?.REACT_APP_TICKET_URL ?? process.env.REACT_APP_TICKET_URL;
}

export const getKeycloakConfig = (): Keycloak.KeycloakConfig => {
    return {
        url: window.smartbuss2?.REACT_APP_KEYCLOACK_URL ?? process.env.REACT_APP_KEYCLOACK_URL!,
        realm: window.smartbuss2?.REACT_APP_KEYCLOACK_REALM ?? process.env.REACT_APP_KEYCLOACK_REALM!,
        clientId: window.smartbuss2?.REACT_APP_KEYCLOACK_CLIENT_ID ?? process.env.REACT_APP_KEYCLOACK_CLIENT_ID!
    }
}

export const getIsTest = (): boolean => {
    const isTestValue = window.smartbuss2?.REACT_APP_IS_TEST ?? process.env.REACT_APP_IS_TEST;
    const isTest = isTestValue && (isTestValue === true || isTestValue === "true");
    return !!isTest;
}

export const getIanaTimezone = () => {
  return window.smartbuss2.REACT_APP_IANA_TIMEZONE ?? process.env.REACT_APP_IANA_TIMEZONE;
}

export const DefaultColumnWidths = {
    number: "120px",
    string_short: "120px",
    string: "180px",
    string_long: "240px",
    string_extra_long: "300px",
    date: "120px",
    date_filterable: "170px",
    time: "120px",
    time_filterable: "130px",
    datetime: "160px",
    boolean: "130px",
    icon: "70px",
    icons: "120px",
    telephone: "150px"
};