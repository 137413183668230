import axios, { AxiosError, AxiosPromise } from 'axios';
import i18n from 'i18next';
import toast from 'react-hot-toast';
import {
  getAccessToken,
  isAuthenticated,
  updateToken
} from 'src/components/pages/Auth/keycloak.config';
import { getApiBaseUrl } from 'src/utils/Smartbuss2Config';


export type ApiError = {
  timestamp?: string;
  statusCode?: number;
  error?: string;
  message?: string;
  path?: string;
};

export type Response<T> = {
  ok: boolean;
  data: T;
  error?: ApiError;
};

const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === 'object') {
    return 'response' in (error as object);
  }
  return false;
};

const isApiError = (error: unknown | null | undefined): error is ApiError => {
  if (error === null || error === undefined) {
    return false;
  }
  if (typeof error === 'object') {
    return 'statusCode' in (error as object);
  }
  return false;
};

axios.interceptors.request.use(
  async config => {
    if (isAuthenticated()) {
      const setAuthorizationHeader = () => {
        if (config.headers) {
          config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        }
        return config;
      };
      
      return await updateToken(setAuthorizationHeader);
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      window.location.href = '/unauthorized';
      toast.error(i18n.t('error.unauthorizedMessage'));
    }

    if (status === 404) {
      toast.error(i18n.t('error.notFoundMessage'));
    }

    if (status === 500) {
      toast.error(i18n.t('error.serverError'));
    }
    
    throw error;
  }
);

// axios.interceptors.response.use(response => {
//   console.log('intercepter', response.data);
//   return response;
// });

async function tryAjax<T>(func: () => AxiosPromise): Promise<Response<T>> {
  try {
    const { data } = await func();
    return {
      ok: true,
      data: data as T
    };
  } catch (error: unknown) {
    
    let apiError: ApiError = {};
    if (isAxiosError(error)) {
      const errorData = error.response?.data;
      if (isApiError(errorData)) {
        apiError = errorData;
      } else {
        apiError = {
          statusCode: error.response?.status
        }
      }
    }

    return {
      ok: false,
      data: {} as unknown as T,
      error: apiError
    };
  }
}

const acceptJsonHeader = {
  Accept: 'application/json'
};

const contentTypeJsonHeader = {
  'Content-Type': 'application/json'
};

export const getRequest = <T = unknown>(url: string, baseUrl?: string, headers?: object) =>
  tryAjax<T>(() => {
    return axios({
      method: 'GET',
      url: url,
      baseURL: baseUrl ?? getApiBaseUrl(),
      headers: {
        ...acceptJsonHeader,
        ...headers
      }
    });
  });

export const postRequest = <T = unknown>(
  url: string,
  data?: unknown,
  excludeContentType?: boolean,
  baseUrl?: string
) => {
  const headers = excludeContentType
    ? {
      ...acceptJsonHeader
    }
    : {
      ...acceptJsonHeader,
      ...contentTypeJsonHeader
    };

  return tryAjax<T>(() =>
    axios({
      method: 'POST',
      url: url,
      baseURL: baseUrl ?? getApiBaseUrl(),
      headers: headers,
      data: data
    })
  );
};

export const putRequest = <T = unknown>(url: string, data?: unknown, baseUrl?: string) =>
  tryAjax<T>(() =>
    axios({
      method: 'PUT',
      url: url,
      baseURL: baseUrl ?? getApiBaseUrl(),
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader
      },
      data: data
    })
  );

export const deleteRequest = <T = unknown>(url: string, data?: unknown, baseUrl?: string) =>
  tryAjax<T>(() =>
    axios({
      method: 'DELETE',
      url: url,
      baseURL: baseUrl ?? getApiBaseUrl(),
      headers: {
        ...acceptJsonHeader
      },
      data: data
    })
  );

export const fetcher = (url: string) =>
  axios.get(url,
    {
      headers: { ...acceptJsonHeader },
      baseURL: getApiBaseUrl()
    })
    .then(res => {
      return res.data;
    });