type IconProps = {
  className?: string;
  size?: number;
  color?: string;
  type: string;
  onClick?: () => void;
  title?: string;
};

export const Icon = ({ type, size = 24, className, onClick, title, color }: IconProps) => {
  return (
    <i
      className={`las ${type} ${className ? className : ''}`}
      style={{ fontSize: size, color: color }}
      onClick={onClick}
      title={title}
    />
  );
};
