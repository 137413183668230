export const round = (number: number | null | undefined, precision: number): number => {
  if (number === undefined || number === null) return 0;
  if (precision < 0) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  } else
    return +(Math.round(Number(number + 'e+' + precision)) + 'e-' + precision);
};

export const financial = (value: number | string | null | undefined) => {
  if (value === null || value === undefined) {
    return '';
  }
  return Number.parseFloat(value.toString()).toFixed(2);
}