import Keycloak from 'keycloak-js';
import { getKeycloakConfig } from 'src/utils/Smartbuss2Config';

export const ACCESS_TOKEN_KEY = 'smartbuss2_token';
const REFRESH_TOKEN_KEY = 'smartbuss2_refresh_token';

export const setTokens = (token: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};


let _keycloak: Keycloak | null = null;

export const getKeycloak = () => {
  if (_keycloak) {
    return _keycloak;
  }
  _keycloak = new Keycloak(getKeycloakConfig());
  return _keycloak;
};

export const isAuthenticated = () => {
  const keycloak = getKeycloak();
  return !!keycloak.token;
};

export const getAccessToken = () => {
  const keycloak = getKeycloak();
  return keycloak.token;
};

export const updateToken = (callback: () => void) => {
  const keycloak = getKeycloak();
  return keycloak.updateToken(60).then(callback).catch(keycloak.login);
};

export const initializeKeycloak = (
  callback: (isAuthenticated: boolean) => void
) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';
  const keycloak = getKeycloak();

  keycloak
    .init({
      onLoad: 'login-required',
      flow: 'standard',
      pkceMethod: 'S256',
      useNonce: true,
      token: token,
      refreshToken: refreshToken,
      checkLoginIframe: false
    })
    .then(callback)
    .catch(console.error);
};
