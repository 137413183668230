import i18n from 'i18next';
import toast from 'react-hot-toast';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from '../../api-handler';
import { UserDto } from 'src/data/models/v2/user/userDto';

export const UserApi = {
  current: async () => {
    const { ok, data } = await getRequest<UserDto>('/api/v2/Users/Current');
    return ok ? ({ ...data } as UserDto) : null;
  },
  getById: async (id: number) => {
    const { ok, data } = await getRequest<UserDto>(`/api/v2/Users/${id}`);

    return ok ? data : null;
  },
  createUser: async (user: UserDto) => {
    const { ok, data, error } = await postRequest<UserDto>('/api/v2/Users', user);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.created')}`);
      return data;
    } else if (error?.statusCode === 409) { // 409 conflict
      toast.error(i18n.t('user.userAlreadyExists'));
      return null;
    } else {
      toast.error(`${i18n.t('crud.createdFailed')}:${error?.message}`);
      return null;
    }
  },
  editUser: async (id: number, user: UserDto) => {
    const { ok, error } = await putRequest(`/api/v2/Users/${id}`, user);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.updated')}`);
      return user;
    } else {
      toast.error(`${i18n.t('crud.updateFailed')}:${error?.message}`);
      return null;
    }
  },
  deleteUser: async (id: number) => {
    const { ok, error } = await deleteRequest(`/api/v2/Users/${id}`);
    if (ok) {
      toast.success(`${i18n.t('user.singular')} ${i18n.t('crud.deleted')}`);
      return id;
    } else {
      toast.error(`${i18n.t('crud.deleteFailed')}:${error?.message}`);
      return null;
    }
  },
  resetPassword: async (id: number, newPassword: string) => {
    const { ok, error } = await putRequest(`/api/v2/Users/${id}/ResetPassword`, {
      temporary: false,
      password: newPassword
    });
    if (ok) {
      toast.success(i18n.t('user.passwordUpdated'));
    } else {
      toast.error(`${i18n.t('user.resetPasswordError')}: ${error?.message}`);
    }
  }
};
